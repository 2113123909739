@tailwind base;

@layer base {
    a {
        @apply hover:text-blue-400 underline underline-offset-4 font-semibold;
    }

    article {
        @apply prose dark:prose-invert lg:prose-lg px-7 md:px-0 pb-10;
    }
}

@tailwind components;
@tailwind utilities;

html, body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }

  #wrapper {
    min-height: 100vh;
  }

  footer {
    min-height: 5rem;
  }
}

dd {
    margin-left: 40px;
}

.iosevka {
    font-family: "Iosevka", monospace;
}
